import Button from "@common/buttons/button"
import { useRouter } from "next/router"
import PropTypes from "prop-types"
import { useState } from "react"
import { hitUrl } from "@common/lib/util"

GetStarted.propTypes = {
  routeLink: PropTypes.string,
  ctaText: PropTypes.string,
  ctaSubText: PropTypes.string,
  inputPlaceHolder: PropTypes.string,
  inputType: PropTypes.string.isRequired,
}
export default function GetStarted({
  routeLink,
  ctaText,
  ctaSubText,
  inputPlaceHolder,
  inputType,
  onSubmit,
}) {
  const [text, setText] = useState("")
  const router = useRouter()

  return (
    <>
      <div className="  p-2 sm:p-0 border  rounded-md transform duration-150 focus-within:shadow-hoveredBlue ">
        <form
          onSubmit={(e) => handleSubmit(e)}
          method="POST"
          className=" sm:flex w-full"
        >
          <label htmlFor={inputType} className="sr-only">
            Email
          </label>
          <input
            onChange={(e) => handleOnChange(e.target.value)}
            type={inputType}
            name={inputType}
            id={inputType}
            required
            className="block w-full py-3 text-base rounded-md placeholder-gray-500 bg-transparent  focus:ring-transparent focus:border-transparent group sm:flex-1 border-none"
            placeholder={inputPlaceHolder}
          />
          <div className="my-auto p-1">
            <Button size="md" width="full" type="submit">
              <p
                className={
                  "m-0 sm:ml-2 sm:mr-1 relative duration-150 ease-in-out" +
                  (text.length ? " -left-1 " : " left-[5px]")
                }
              >
                {ctaText || "Get Started"}
              </p>
              <p
                className={
                  text.length ? " visible animate-fadeInFromLeft " : " invisible"
                }
              >
                &rarr;
              </p>
            </Button>
          </div>
        </form>
      </div>
      {renderCtaSubText()}
    </>
  )

  function renderCtaSubText() {
    if (!ctaSubText) return
    return (
      <div className="text-muted-foreground uppercase text-xxs my-2">{ctaSubText}</div>
    )
  }

  function handleOnChange(text) {
    setText(text)
  }

  function handleSubmit(e) {
    e.preventDefault()
    if (onSubmit) {
      onSubmit(text)
      return
    }
    const source = window.location.href || ""
    const url = `https://docs.google.com/forms/d/e/1FAIpQLSdjd81MsCbpLC3vIorysEmsUExPFETETUWN1EoVuLCzsfTvfg/formResponse?usp=pp_url&entry.1121708413=${text}&entry.85537494=${source}&submit=Submit`
    hitUrl(url)
    router.push(
      routeLink ? `${routeLink}?email=${text}` : `/signup?email=${text}`
    )
  }
}
