import React from "react"

export default function StatsUnit({ statsData, backgroundStyles = null }) {
  // statsData         👉 ARRAY OF OBJECT/S WITH stat & statName AS PROP EX: [{ stat: "45X", statName: "Categories"}]
  // backgroundStyles  👉  PURE CSS PROPERTY
  return (
    <>
      <div
        style={{
          background: backgroundStyles ?? "",
        }}
        className="max-w-full max-h-full"
      >
        <div className={`grid gap-24 px-4 md:px-0 grid-cols-2 md:grid-cols-4 `}>
          {statsData.map(({ stat, statName }, index) => {
            return (
              <div className="  " key={index}>
                <div>
                  <p className="text-center text-blue-600 text-3xl lg:text-4xl font-bold mb-3">
                    {stat}
                  </p>
                  <p className="text-center text-primary  lg:text-center text-xs sm:text-sm md:text-base  uppercase">
                    {statName}
                  </p>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </>
  )
}
