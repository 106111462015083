import React, { useEffect, useRef, useState } from "react"
import ImageWrapper from "../imageWrapper"

let timer = null
export default function ImageTabs({ isDarkMode, tabData, isAutoLoop = true }) {
  // tabData    👉 ARRAY OF OBJECT/S WITH imgPath AND label AS PROP, EX: [{imgPath: "/images/hero_screenshots/sc_1.svg", label: "First"}]
  // isAutoLoop 👉 IF TO AUTO LOOP OVER TABS
  //            📝 THE TABS AND THE AUTO LOOP LOGIC WILL BE ACTIVE ONLY IF TABDATA.length > 1

  const [activeTabIndex, setActiveTabIndex] = useState(0)
  const [stopLooping, setStopLooping] = useState(false)
  const [lineWidth, setLineWidth] = useState(0)
  const [linePosition, setLinePosition] = useState(0)
  const tabsRef = useRef([])
  const imageChangeDuration = 5 // ---> IN SECONDS

  useEffect(() => {
    /* LOGIC FOR ANIMATED TAB SELECT SLIDER */
    function setTabPosition() {
      const currentTab = tabsRef.current[activeTabIndex]
      setLinePosition(currentTab?.offsetLeft ?? 0)
      setLineWidth(currentTab?.clientWidth ?? 0)
    }
    setTabPosition()
    window.addEventListener("resize", setTabPosition)
    /* ------------------------------------ */

    /* LOGIC FOR AUTO LOOP OVER TABS */
    if (!stopLooping && isEligibleForAutoLoop()) {
      timer = setInterval(() => {
        setActiveTabIndex((prev) => {
          if (prev < tabData?.length - 1) {
            return prev + 1
          } else {
            return 0
          }
        })
      }, imageChangeDuration * 1000)
    }
    /* --------------------------- */
    return () => {
      window.removeEventListener("resize", setTabPosition)
      clearInterval(timer)
    }
  }, [activeTabIndex])

  function handleTabClick(index) {
    clearInterval(timer)
    setStopLooping(true)
    setActiveTabIndex(index)
  }
  function isEligibleForAutoLoop() {
    return isAutoLoop && tabData?.length > 1
  }
  return (
    <div className="  relative ">
      <div className="  flex justify-center">
        <div className=" relative w-11/12 md:w-10/12 lg:w-9/12 xl:w-8/12 pb-10  ">
          <>
            {isEligibleForAutoLoop() && (
              <div className="relative my-4 xl:mb-10">
                <span
                  className="absolute top-0 block h-1 bg-blue-600 transition-all duration-300"
                  style={{ left: linePosition, width: lineWidth }}
                />
                <div className="flex justify-between align-middle border-t-4 border-gray-200 dark:border-gray-800">
                  {tabData.map(({ label }, index) => {
                    return (
                      <button
                        key={index}
                        ref={(el) => (tabsRef.current[index] = el)}
                        className={
                          "w-full py-3 px-2 hover:text-blue-600 font-semibold text-xxs sm:text-sm md:text-md lg:text-lg " +
                          (activeTabIndex == index
                            ? "text-blue-600"
                            : " text-foreground")
                        }
                        onClick={() => handleTabClick(index)}
                      >
                        {label}
                      </button>
                    )
                  })}
                </div>
              </div>
            )}
          </>

          <div className="rounded-lg border  w-full left-0 right-0 m-auto animate-mildFade shadow-elevate1 dark:shadow-elevate1Dark">
            <ImageWrapper
              src={tabData[activeTabIndex].imgPath}
              blurDataURL={"/images/backupImages/landingPage_ss_fallback.jpg"}
              width={1920}
              height={1080}
              className="rounded-lg"
              isDarkMode={isDarkMode}
              darkSrc={tabData[activeTabIndex].imgPathDark}
            />
          </div>
        </div>
      </div>
      <div className=" absolute w-full bottom-0 " />
    </div>
  )
}
